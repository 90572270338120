<template>
  <div class="ordeDdetaMind">
    <div class="ordeDdetaTop"></div>
    <div class="ordeDdetaBody">
      <div class="ordeDdetaRow">
        <div class="ordeVertical"></div>
        <div>收货地址</div>
      </div>
      <div class="ordeDdetaCardRow">
        <div class="ordeDdetaCard">
          <div class="ordeDdetaCardAdd">
            <img src="../../assets/jia.png" class="addImg" />
            <div>添加地址</div>
          </div>
        </div>
      </div>
      <div class="ordeDdetaRow">
        <div class="ordeVertical"></div>
        <div>支付方式</div>
      </div>
      <div class="ordeDdetaRidoRow">
        <el-radio-group v-model="radio">
          <el-radio :label="3">支付宝支付</el-radio>
          <el-radio :label="6">微信支付</el-radio>
        </el-radio-group>
      </div>
      <div class="ordeDdetaRow">
        <div class="ordeVertical"></div>
        <div>购买信息</div>
      </div>
      <div class="ordeDdetaBuy">
        <div class="ordeDdetaBuyBody">
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: 'white',
              color: '#242424',
              'text-align': 'center',
            }"
            :row-style="{ background: '#F2F7FF', 'text-align': 'center' }"
            style="width: 100%"
          >
            <el-table-column label="商品" width="400">
              <template #default="scope">
                <div class="ordeDdetaBuyRow">
                  <img :src="scope.row.url" class="ordeDdetaBuyImg" />
                  <div>{{ scope.row.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="单价（元）" width="120">
              <template #default="scope">
                <div class="buyMoney">{{ scope.row.money }}</div>
              </template>
            </el-table-column>
            <el-table-column label="数量" width="220">
              <template #default="scope">
                <div class="ordeDdetaBuyNum">
                  <div class="buyNumReduce">-</div>
                  <div class="buyNum">{{ scope.row.num }}</div>
                  <div class="buyNumReduce">+</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="小计（元）" width="120">
              <template #default="scope">
                <div class="buyAllMoney">{{ scope.row.allMoney }}</div>
              </template>
            </el-table-column>
            <el-table-column label="删除">
              <template #default="scope">
                <div class="buyDelete">
                  <img
                    src="../../assets/delete.png"
                    class="deleteImg"
                    @click="deleteClick(scope.row)"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="ordeDdetaBuyChecked">
            <el-checkbox v-model="checked">开具发票</el-checkbox>
        </div>
        <div class="ordeDdetaMoneyRow">
            <div style="color:#414142;font-size:16px">运费险：</div>
            <div style="color:#FF0036;font-size:20px;margin-right:20px">¥30</div>
            <div style="color:#414142;font-size:16px">实付款：</div>
            <div style="color:#FF0036;font-size:20px;">¥1680.00</div>
        </div>
      </div>
      <div class="ordeDdetaFoot">
        <div class="ordeDdetaFootBtn">提交订单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: "",
      tableData: [
        {
          url: "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112172033_buycom.png",
          money: 1200,
          num: 1,
          name: "爱牵挂 居家安全报警器",
          allMoney: 1200,
        },
      ],
      checked:false
    };
  },
  created() {},
  methods: {
    // 删除表格当前行
    deleteClick() {},
  },
};
</script>

<style>
.ordeDdetaMind {
  width: 100%;
  height: 100%;
  background-color: #f5f7f9;
  padding-top: 80px;
  box-sizing: border-box;
}

.ordeDdetaTop {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  width: 100%;
  height: 80px;
  background-color: white;
}

.ordeDdetaBody {
  width: 100%;
  min-height: 80px;
  padding: 20px 20%;
  box-sizing: border-box;
}

.ordeDdetaRow {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: #242424;
  font-size: 16px;
}

.ordeVertical {
  width: 4px;
  height: 20px;
  background-color: #3d7eff;
  margin-right: 16px;
}

.ordeDdetaCardRow {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
}

.ordeDdetaCard {
  width: 280px;
  height: 160px;
  background-color: white;
}

.ordeDdetaCardAdd {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #242424;
  font-size: 16px;
}

.addImg {
  width: 32px;
  height: 32px;
  margin-bottom: 15px;
}

.ordeDdetaRidoRow {
  width: 100%;
  height: 80px;
}

.ordeDdetaBuy {
  width: 100%;
  min-height: 200px;
  background-color: #f2f7ff;
}

.ordeDdetaBuyBody {
  width: 100%;
  min-height: 100px;
}

.ordeDdetaBuyRow {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
  color: #242424;
  font-size: 16px;
}

.ordeDdetaBuyImg {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.ordeDdetaBuyNum {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyNumReduce {
  width: 42px;
  height: 42px;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 40px;
  color: #242424;
  font-size: 16px;
  background-color: white;
}

.buyNum {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #242424;
  font-size: 16px;
  background-color: white;
}

.buyMoney {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyAllMoney {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d7eff;
}

.buyDelete {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteImg {
  width: 23px;
  height: 23px;
}

.ordeDdetaBuyChecked{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #414142;
    font-size: 20px;
}

.ordeDdetaMoneyRow{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ordeDdetaFoot{
    width: 100%;
    height: 60px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ordeDdetaFootBtn{
    width: 140px;
    height: 36px;
    border-radius: 20px;
    border: 1px solid #2680EB;
    color: #1764FC;
    font-size: 20px;
    text-align: center;
    line-height: 36px;
}
</style>